@import '/src/styles/utilities.scss';

.shareModal__heading {
  margin-bottom: $space-48;
}

.shareModal__socialButtons {
  display: flex;
  gap: $space-48;
  justify-content: center;
  margin-bottom: $space-24;
}

.shareModal__subtitle {
  margin-bottom: $space-4;
  color: var(--color-gray-400);
}

.shareModal__linkBlock {
  @include form-input();

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shareModal__copiedText {
  color: var(--color-alert-success-300);
}

.shareModal__link {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  font-family: $font-primary;
  font-size: 1.8rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.shareModal__copyButton {
  @include undo-default-button-styles();
  @include text-button-effects();
}
